var render = function () {
  var _vm$authUser;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Dashboard & Roles",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.dashboard,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row$permission, _props$row3, _props$row4, _props$row4$permissio, _props$row5, _props$row6, _props$row6$permissio, _props$row7, _props$row8, _props$row8$permissio, _props$row9, _props$row10, _props$row10$permissi, _props$row11, _props$row12, _props$row12$permissi, _props$row13, _props$row14, _props$row14$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$permission = _props$row.permissions) === null || _props$row$permission === void 0 ? void 0 : _props$row$permission.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row2, _props$row2$permissio;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$permissio = _props$row2.permissions) === null || _props$row2$permissio === void 0 ? void 0 : _props$row2$permissio.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.permissions ? [_vm._l((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$permissio = _props$row4.permissions) === null || _props$row4$permissio === void 0 ? void 0 : _props$row4$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.permissions ? [_vm._l((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$permissio = _props$row6.permissions) === null || _props$row6$permissio === void 0 ? void 0 : _props$row6$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.permissions ? [_vm._l((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : (_props$row8$permissio = _props$row8.permissions) === null || _props$row8$permissio === void 0 ? void 0 : _props$row8$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.permissions ? [_vm._l((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : (_props$row10$permissi = _props$row10.permissions) === null || _props$row10$permissi === void 0 ? void 0 : _props$row10$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row11 = props.row) !== null && _props$row11 !== void 0 && _props$row11.permissions ? [_vm._l((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$permissi = _props$row12.permissions) === null || _props$row12$permissi === void 0 ? void 0 : _props$row12$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row13 = props.row) !== null && _props$row13 !== void 0 && _props$row13.permissions ? _vm._l((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : (_props$row14$permissi = _props$row14.permissions) === null || _props$row14$permissi === void 0 ? void 0 : _props$row14$permissi.data, function (item, index) {
          var _props$row15, _props$row15$permissi, _props$row16, _props$row16$permissi;
          return _c('div', {
            key: index
          }, [index % 2 === 0 ? _c('b-row', [_c('b-col', {
            attrs: {
              "cols": "6"
            }
          }, [item.group === 'others' ? [_c('b-form-checkbox', {
            key: item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item.name)) + " ")])] : _vm._e()], 2), (_props$row15 = props.row) !== null && _props$row15 !== void 0 && (_props$row15$permissi = _props$row15.permissions) !== null && _props$row15$permissi !== void 0 && _props$row15$permissi.data[index + 1] ? _c('b-col', {
            attrs: {
              "cols": "6"
            }
          }, [((_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : (_props$row16$permissi = _props$row16.permissions) === null || _props$row16$permissi === void 0 ? void 0 : _props$row16$permissi.data[index + 1].group) === 'others' ? [_c('b-form-checkbox', {
            key: props.row.permissions.data[index + 1].id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(props.row.permissions.data[index + 1].id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, props.row.permissions.data[index + 1].id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(props.row.permissions.data[index + 1].name)) + " ")])] : _vm._e()], 2) : _vm._e()], 1) : _vm._e()], 1);
        }) : _vm._e()], 2) : _vm._e()];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "HRMS",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.hrms,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row17, _props$row17$permissi, _props$row19, _props$row20, _props$row20$permissi, _props$row21, _props$row22, _props$row22$permissi, _props$row23, _props$row24, _props$row24$permissi, _props$row25, _props$row26, _props$row26$permissi, _props$row27, _props$row28, _props$row28$permissi, _props$row29, _props$row30, _props$row30$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : (_props$row17$permissi = _props$row17.permissions) === null || _props$row17$permissi === void 0 ? void 0 : _props$row17$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row18, _props$row18$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : (_props$row18$permissi = _props$row18.permissions) === null || _props$row18$permissi === void 0 ? void 0 : _props$row18$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row19 = props.row) !== null && _props$row19 !== void 0 && _props$row19.permissions ? [_vm._l((_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : (_props$row20$permissi = _props$row20.permissions) === null || _props$row20$permissi === void 0 ? void 0 : _props$row20$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row21 = props.row) !== null && _props$row21 !== void 0 && _props$row21.permissions ? [_vm._l((_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : (_props$row22$permissi = _props$row22.permissions) === null || _props$row22$permissi === void 0 ? void 0 : _props$row22$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row23 = props.row) !== null && _props$row23 !== void 0 && _props$row23.permissions ? [_vm._l((_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : (_props$row24$permissi = _props$row24.permissions) === null || _props$row24$permissi === void 0 ? void 0 : _props$row24$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row25 = props.row) !== null && _props$row25 !== void 0 && _props$row25.permissions ? [_vm._l((_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : (_props$row26$permissi = _props$row26.permissions) === null || _props$row26$permissi === void 0 ? void 0 : _props$row26$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row27 = props.row) !== null && _props$row27 !== void 0 && _props$row27.permissions ? [_vm._l((_props$row28 = props.row) === null || _props$row28 === void 0 ? void 0 : (_props$row28$permissi = _props$row28.permissions) === null || _props$row28$permissi === void 0 ? void 0 : _props$row28$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row29 = props.row) !== null && _props$row29 !== void 0 && _props$row29.permissions ? [_vm._l((_props$row30 = props.row) === null || _props$row30 === void 0 ? void 0 : (_props$row30$permissi = _props$row30.permissions) === null || _props$row30$permissi === void 0 ? void 0 : _props$row30$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Lead",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.lead,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row31, _props$row31$permissi, _props$row33, _props$row34, _props$row34$permissi, _props$row35, _props$row36, _props$row36$permissi, _props$row37, _props$row38, _props$row38$permissi, _props$row39, _props$row40, _props$row40$permissi, _props$row41, _props$row42, _props$row42$permissi, _props$row43, _props$row44, _props$row44$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row31 = props.row) === null || _props$row31 === void 0 ? void 0 : (_props$row31$permissi = _props$row31.permissions) === null || _props$row31$permissi === void 0 ? void 0 : _props$row31$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row32, _props$row32$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row32 = props.row) === null || _props$row32 === void 0 ? void 0 : (_props$row32$permissi = _props$row32.permissions) === null || _props$row32$permissi === void 0 ? void 0 : _props$row32$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row33 = props.row) !== null && _props$row33 !== void 0 && _props$row33.permissions ? [_vm._l((_props$row34 = props.row) === null || _props$row34 === void 0 ? void 0 : (_props$row34$permissi = _props$row34.permissions) === null || _props$row34$permissi === void 0 ? void 0 : _props$row34$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row35 = props.row) !== null && _props$row35 !== void 0 && _props$row35.permissions ? [_vm._l((_props$row36 = props.row) === null || _props$row36 === void 0 ? void 0 : (_props$row36$permissi = _props$row36.permissions) === null || _props$row36$permissi === void 0 ? void 0 : _props$row36$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row37 = props.row) !== null && _props$row37 !== void 0 && _props$row37.permissions ? [_vm._l((_props$row38 = props.row) === null || _props$row38 === void 0 ? void 0 : (_props$row38$permissi = _props$row38.permissions) === null || _props$row38$permissi === void 0 ? void 0 : _props$row38$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row39 = props.row) !== null && _props$row39 !== void 0 && _props$row39.permissions ? [_vm._l((_props$row40 = props.row) === null || _props$row40 === void 0 ? void 0 : (_props$row40$permissi = _props$row40.permissions) === null || _props$row40$permissi === void 0 ? void 0 : _props$row40$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row41 = props.row) !== null && _props$row41 !== void 0 && _props$row41.permissions ? [_vm._l((_props$row42 = props.row) === null || _props$row42 === void 0 ? void 0 : (_props$row42$permissi = _props$row42.permissions) === null || _props$row42$permissi === void 0 ? void 0 : _props$row42$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row43 = props.row) !== null && _props$row43 !== void 0 && _props$row43.permissions ? [_vm._l((_props$row44 = props.row) === null || _props$row44 === void 0 ? void 0 : (_props$row44$permissi = _props$row44.permissions) === null || _props$row44$permissi === void 0 ? void 0 : _props$row44$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Finance",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.finance,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row45, _props$row45$permissi, _props$row47, _props$row48, _props$row48$permissi, _props$row49, _props$row50, _props$row50$permissi, _props$row51, _props$row52, _props$row52$permissi, _props$row53, _props$row54, _props$row54$permissi, _props$row55, _props$row56, _props$row56$permissi, _props$row57, _props$row58, _props$row58$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row45 = props.row) === null || _props$row45 === void 0 ? void 0 : (_props$row45$permissi = _props$row45.permissions) === null || _props$row45$permissi === void 0 ? void 0 : _props$row45$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row46, _props$row46$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row46 = props.row) === null || _props$row46 === void 0 ? void 0 : (_props$row46$permissi = _props$row46.permissions) === null || _props$row46$permissi === void 0 ? void 0 : _props$row46$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row47 = props.row) !== null && _props$row47 !== void 0 && _props$row47.permissions ? [_vm._l((_props$row48 = props.row) === null || _props$row48 === void 0 ? void 0 : (_props$row48$permissi = _props$row48.permissions) === null || _props$row48$permissi === void 0 ? void 0 : _props$row48$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row49 = props.row) !== null && _props$row49 !== void 0 && _props$row49.permissions ? [_vm._l((_props$row50 = props.row) === null || _props$row50 === void 0 ? void 0 : (_props$row50$permissi = _props$row50.permissions) === null || _props$row50$permissi === void 0 ? void 0 : _props$row50$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row51 = props.row) !== null && _props$row51 !== void 0 && _props$row51.permissions ? [_vm._l((_props$row52 = props.row) === null || _props$row52 === void 0 ? void 0 : (_props$row52$permissi = _props$row52.permissions) === null || _props$row52$permissi === void 0 ? void 0 : _props$row52$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row53 = props.row) !== null && _props$row53 !== void 0 && _props$row53.permissions ? [_vm._l((_props$row54 = props.row) === null || _props$row54 === void 0 ? void 0 : (_props$row54$permissi = _props$row54.permissions) === null || _props$row54$permissi === void 0 ? void 0 : _props$row54$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row55 = props.row) !== null && _props$row55 !== void 0 && _props$row55.permissions ? [_vm._l((_props$row56 = props.row) === null || _props$row56 === void 0 ? void 0 : (_props$row56$permissi = _props$row56.permissions) === null || _props$row56$permissi === void 0 ? void 0 : _props$row56$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row57 = props.row) !== null && _props$row57 !== void 0 && _props$row57.permissions ? [_vm._l((_props$row58 = props.row) === null || _props$row58 === void 0 ? void 0 : (_props$row58$permissi = _props$row58.permissions) === null || _props$row58$permissi === void 0 ? void 0 : _props$row58$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Task",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.task,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row59, _props$row59$permissi, _props$row61, _props$row62, _props$row62$permissi, _props$row63, _props$row64, _props$row64$permissi, _props$row65, _props$row66, _props$row66$permissi, _props$row67, _props$row68, _props$row68$permissi, _props$row69, _props$row70, _props$row70$permissi, _props$row71, _props$row72, _props$row72$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row59 = props.row) === null || _props$row59 === void 0 ? void 0 : (_props$row59$permissi = _props$row59.permissions) === null || _props$row59$permissi === void 0 ? void 0 : _props$row59$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row60, _props$row60$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row60 = props.row) === null || _props$row60 === void 0 ? void 0 : (_props$row60$permissi = _props$row60.permissions) === null || _props$row60$permissi === void 0 ? void 0 : _props$row60$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row61 = props.row) !== null && _props$row61 !== void 0 && _props$row61.permissions ? [_vm._l((_props$row62 = props.row) === null || _props$row62 === void 0 ? void 0 : (_props$row62$permissi = _props$row62.permissions) === null || _props$row62$permissi === void 0 ? void 0 : _props$row62$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row63 = props.row) !== null && _props$row63 !== void 0 && _props$row63.permissions ? [_vm._l((_props$row64 = props.row) === null || _props$row64 === void 0 ? void 0 : (_props$row64$permissi = _props$row64.permissions) === null || _props$row64$permissi === void 0 ? void 0 : _props$row64$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row65 = props.row) !== null && _props$row65 !== void 0 && _props$row65.permissions ? [_vm._l((_props$row66 = props.row) === null || _props$row66 === void 0 ? void 0 : (_props$row66$permissi = _props$row66.permissions) === null || _props$row66$permissi === void 0 ? void 0 : _props$row66$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row67 = props.row) !== null && _props$row67 !== void 0 && _props$row67.permissions ? [_vm._l((_props$row68 = props.row) === null || _props$row68 === void 0 ? void 0 : (_props$row68$permissi = _props$row68.permissions) === null || _props$row68$permissi === void 0 ? void 0 : _props$row68$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row69 = props.row) !== null && _props$row69 !== void 0 && _props$row69.permissions ? [_vm._l((_props$row70 = props.row) === null || _props$row70 === void 0 ? void 0 : (_props$row70$permissi = _props$row70.permissions) === null || _props$row70$permissi === void 0 ? void 0 : _props$row70$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row71 = props.row) !== null && _props$row71 !== void 0 && _props$row71.permissions ? [_vm._l((_props$row72 = props.row) === null || _props$row72 === void 0 ? void 0 : (_props$row72$permissi = _props$row72.permissions) === null || _props$row72$permissi === void 0 ? void 0 : _props$row72$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Asset",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.asset,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row73, _props$row73$permissi, _props$row75, _props$row76, _props$row76$permissi, _props$row77, _props$row78, _props$row78$permissi, _props$row79, _props$row80, _props$row80$permissi, _props$row81, _props$row82, _props$row82$permissi, _props$row83, _props$row84, _props$row84$permissi, _props$row85, _props$row86, _props$row86$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row73 = props.row) === null || _props$row73 === void 0 ? void 0 : (_props$row73$permissi = _props$row73.permissions) === null || _props$row73$permissi === void 0 ? void 0 : _props$row73$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row74, _props$row74$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row74 = props.row) === null || _props$row74 === void 0 ? void 0 : (_props$row74$permissi = _props$row74.permissions) === null || _props$row74$permissi === void 0 ? void 0 : _props$row74$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row75 = props.row) !== null && _props$row75 !== void 0 && _props$row75.permissions ? [_vm._l((_props$row76 = props.row) === null || _props$row76 === void 0 ? void 0 : (_props$row76$permissi = _props$row76.permissions) === null || _props$row76$permissi === void 0 ? void 0 : _props$row76$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row77 = props.row) !== null && _props$row77 !== void 0 && _props$row77.permissions ? [_vm._l((_props$row78 = props.row) === null || _props$row78 === void 0 ? void 0 : (_props$row78$permissi = _props$row78.permissions) === null || _props$row78$permissi === void 0 ? void 0 : _props$row78$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row79 = props.row) !== null && _props$row79 !== void 0 && _props$row79.permissions ? [_vm._l((_props$row80 = props.row) === null || _props$row80 === void 0 ? void 0 : (_props$row80$permissi = _props$row80.permissions) === null || _props$row80$permissi === void 0 ? void 0 : _props$row80$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row81 = props.row) !== null && _props$row81 !== void 0 && _props$row81.permissions ? [_vm._l((_props$row82 = props.row) === null || _props$row82 === void 0 ? void 0 : (_props$row82$permissi = _props$row82.permissions) === null || _props$row82$permissi === void 0 ? void 0 : _props$row82$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row83 = props.row) !== null && _props$row83 !== void 0 && _props$row83.permissions ? [_vm._l((_props$row84 = props.row) === null || _props$row84 === void 0 ? void 0 : (_props$row84$permissi = _props$row84.permissions) === null || _props$row84$permissi === void 0 ? void 0 : _props$row84$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row85 = props.row) !== null && _props$row85 !== void 0 && _props$row85.permissions ? [_vm._l((_props$row86 = props.row) === null || _props$row86 === void 0 ? void 0 : (_props$row86$permissi = _props$row86.permissions) === null || _props$row86$permissi === void 0 ? void 0 : _props$row86$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Notice",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.notice,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row87, _props$row87$permissi, _props$row89, _props$row90, _props$row90$permissi, _props$row91, _props$row92, _props$row92$permissi, _props$row93, _props$row94, _props$row94$permissi, _props$row95, _props$row96, _props$row96$permissi, _props$row97, _props$row98, _props$row98$permissi, _props$row99, _props$row100, _props$row100$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row87 = props.row) === null || _props$row87 === void 0 ? void 0 : (_props$row87$permissi = _props$row87.permissions) === null || _props$row87$permissi === void 0 ? void 0 : _props$row87$permissi.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row88, _props$row88$permissi;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row88 = props.row) === null || _props$row88 === void 0 ? void 0 : (_props$row88$permissi = _props$row88.permissions) === null || _props$row88$permissi === void 0 ? void 0 : _props$row88$permissi.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row89 = props.row) !== null && _props$row89 !== void 0 && _props$row89.permissions ? [_vm._l((_props$row90 = props.row) === null || _props$row90 === void 0 ? void 0 : (_props$row90$permissi = _props$row90.permissions) === null || _props$row90$permissi === void 0 ? void 0 : _props$row90$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row91 = props.row) !== null && _props$row91 !== void 0 && _props$row91.permissions ? [_vm._l((_props$row92 = props.row) === null || _props$row92 === void 0 ? void 0 : (_props$row92$permissi = _props$row92.permissions) === null || _props$row92$permissi === void 0 ? void 0 : _props$row92$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row93 = props.row) !== null && _props$row93 !== void 0 && _props$row93.permissions ? [_vm._l((_props$row94 = props.row) === null || _props$row94 === void 0 ? void 0 : (_props$row94$permissi = _props$row94.permissions) === null || _props$row94$permissi === void 0 ? void 0 : _props$row94$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row95 = props.row) !== null && _props$row95 !== void 0 && _props$row95.permissions ? [_vm._l((_props$row96 = props.row) === null || _props$row96 === void 0 ? void 0 : (_props$row96$permissi = _props$row96.permissions) === null || _props$row96$permissi === void 0 ? void 0 : _props$row96$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row97 = props.row) !== null && _props$row97 !== void 0 && _props$row97.permissions ? [_vm._l((_props$row98 = props.row) === null || _props$row98 === void 0 ? void 0 : (_props$row98$permissi = _props$row98.permissions) === null || _props$row98$permissi === void 0 ? void 0 : _props$row98$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row99 = props.row) !== null && _props$row99 !== void 0 && _props$row99.permissions ? [_vm._l((_props$row100 = props.row) === null || _props$row100 === void 0 ? void 0 : (_props$row100$permiss = _props$row100.permissions) === null || _props$row100$permiss === void 0 ? void 0 : _props$row100$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Loan",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.loan,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row101, _props$row101$permiss, _props$row103, _props$row104, _props$row104$permiss, _props$row105, _props$row106, _props$row106$permiss, _props$row107, _props$row108, _props$row108$permiss, _props$row109, _props$row110, _props$row110$permiss, _props$row111, _props$row112, _props$row112$permiss, _props$row113, _props$row114, _props$row114$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row101 = props.row) === null || _props$row101 === void 0 ? void 0 : (_props$row101$permiss = _props$row101.permissions) === null || _props$row101$permiss === void 0 ? void 0 : _props$row101$permiss.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row102, _props$row102$permiss;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row102 = props.row) === null || _props$row102 === void 0 ? void 0 : (_props$row102$permiss = _props$row102.permissions) === null || _props$row102$permiss === void 0 ? void 0 : _props$row102$permiss.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row103 = props.row) !== null && _props$row103 !== void 0 && _props$row103.permissions ? [_vm._l((_props$row104 = props.row) === null || _props$row104 === void 0 ? void 0 : (_props$row104$permiss = _props$row104.permissions) === null || _props$row104$permiss === void 0 ? void 0 : _props$row104$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row105 = props.row) !== null && _props$row105 !== void 0 && _props$row105.permissions ? [_vm._l((_props$row106 = props.row) === null || _props$row106 === void 0 ? void 0 : (_props$row106$permiss = _props$row106.permissions) === null || _props$row106$permiss === void 0 ? void 0 : _props$row106$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row107 = props.row) !== null && _props$row107 !== void 0 && _props$row107.permissions ? [_vm._l((_props$row108 = props.row) === null || _props$row108 === void 0 ? void 0 : (_props$row108$permiss = _props$row108.permissions) === null || _props$row108$permiss === void 0 ? void 0 : _props$row108$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row109 = props.row) !== null && _props$row109 !== void 0 && _props$row109.permissions ? [_vm._l((_props$row110 = props.row) === null || _props$row110 === void 0 ? void 0 : (_props$row110$permiss = _props$row110.permissions) === null || _props$row110$permiss === void 0 ? void 0 : _props$row110$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row111 = props.row) !== null && _props$row111 !== void 0 && _props$row111.permissions ? [_vm._l((_props$row112 = props.row) === null || _props$row112 === void 0 ? void 0 : (_props$row112$permiss = _props$row112.permissions) === null || _props$row112$permiss === void 0 ? void 0 : _props$row112$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row113 = props.row) !== null && _props$row113 !== void 0 && _props$row113.permissions ? [_vm._l((_props$row114 = props.row) === null || _props$row114 === void 0 ? void 0 : (_props$row114$permiss = _props$row114.permissions) === null || _props$row114$permiss === void 0 ? void 0 : _props$row114$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Payroll",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.payroll,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row115, _props$row115$permiss, _props$row117, _props$row118, _props$row118$permiss, _props$row119, _props$row120, _props$row120$permiss, _props$row121, _props$row122, _props$row122$permiss, _props$row123, _props$row124, _props$row124$permiss, _props$row125, _props$row126, _props$row126$permiss, _props$row127, _props$row128, _props$row128$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row115 = props.row) === null || _props$row115 === void 0 ? void 0 : (_props$row115$permiss = _props$row115.permissions) === null || _props$row115$permiss === void 0 ? void 0 : _props$row115$permiss.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row116, _props$row116$permiss;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row116 = props.row) === null || _props$row116 === void 0 ? void 0 : (_props$row116$permiss = _props$row116.permissions) === null || _props$row116$permiss === void 0 ? void 0 : _props$row116$permiss.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row117 = props.row) !== null && _props$row117 !== void 0 && _props$row117.permissions ? [_vm._l((_props$row118 = props.row) === null || _props$row118 === void 0 ? void 0 : (_props$row118$permiss = _props$row118.permissions) === null || _props$row118$permiss === void 0 ? void 0 : _props$row118$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row119 = props.row) !== null && _props$row119 !== void 0 && _props$row119.permissions ? [_vm._l((_props$row120 = props.row) === null || _props$row120 === void 0 ? void 0 : (_props$row120$permiss = _props$row120.permissions) === null || _props$row120$permiss === void 0 ? void 0 : _props$row120$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row121 = props.row) !== null && _props$row121 !== void 0 && _props$row121.permissions ? [_vm._l((_props$row122 = props.row) === null || _props$row122 === void 0 ? void 0 : (_props$row122$permiss = _props$row122.permissions) === null || _props$row122$permiss === void 0 ? void 0 : _props$row122$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row123 = props.row) !== null && _props$row123 !== void 0 && _props$row123.permissions ? [_vm._l((_props$row124 = props.row) === null || _props$row124 === void 0 ? void 0 : (_props$row124$permiss = _props$row124.permissions) === null || _props$row124$permiss === void 0 ? void 0 : _props$row124$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row125 = props.row) !== null && _props$row125 !== void 0 && _props$row125.permissions ? [_vm._l((_props$row126 = props.row) === null || _props$row126 === void 0 ? void 0 : (_props$row126$permiss = _props$row126.permissions) === null || _props$row126$permiss === void 0 ? void 0 : _props$row126$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row127 = props.row) !== null && _props$row127 !== void 0 && _props$row127.permissions ? [_vm._l((_props$row128 = props.row) === null || _props$row128 === void 0 ? void 0 : (_props$row128$permiss = _props$row128.permissions) === null || _props$row128$permiss === void 0 ? void 0 : _props$row128$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Settings",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.settings,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row129, _props$row129$permiss, _props$row131, _props$row132, _props$row132$permiss, _props$row133, _props$row134, _props$row134$permiss, _props$row135, _props$row136, _props$row136$permiss, _props$row137, _props$row138, _props$row138$permiss, _props$row139, _props$row140, _props$row140$permiss, _props$row141, _props$row142, _props$row142$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row129 = props.row) === null || _props$row129 === void 0 ? void 0 : (_props$row129$permiss = _props$row129.permissions) === null || _props$row129$permiss === void 0 ? void 0 : _props$row129$permiss.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row130, _props$row130$permiss;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row130 = props.row) === null || _props$row130 === void 0 ? void 0 : (_props$row130$permiss = _props$row130.permissions) === null || _props$row130$permiss === void 0 ? void 0 : _props$row130$permiss.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row131 = props.row) !== null && _props$row131 !== void 0 && _props$row131.permissions ? [_vm._l((_props$row132 = props.row) === null || _props$row132 === void 0 ? void 0 : (_props$row132$permiss = _props$row132.permissions) === null || _props$row132$permiss === void 0 ? void 0 : _props$row132$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row133 = props.row) !== null && _props$row133 !== void 0 && _props$row133.permissions ? [_vm._l((_props$row134 = props.row) === null || _props$row134 === void 0 ? void 0 : (_props$row134$permiss = _props$row134.permissions) === null || _props$row134$permiss === void 0 ? void 0 : _props$row134$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row135 = props.row) !== null && _props$row135 !== void 0 && _props$row135.permissions ? [_vm._l((_props$row136 = props.row) === null || _props$row136 === void 0 ? void 0 : (_props$row136$permiss = _props$row136.permissions) === null || _props$row136$permiss === void 0 ? void 0 : _props$row136$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row137 = props.row) !== null && _props$row137 !== void 0 && _props$row137.permissions ? [_vm._l((_props$row138 = props.row) === null || _props$row138 === void 0 ? void 0 : (_props$row138$permiss = _props$row138.permissions) === null || _props$row138$permiss === void 0 ? void 0 : _props$row138$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row139 = props.row) !== null && _props$row139 !== void 0 && _props$row139.permissions ? [_vm._l((_props$row140 = props.row) === null || _props$row140 === void 0 ? void 0 : (_props$row140$permiss = _props$row140.permissions) === null || _props$row140$permiss === void 0 ? void 0 : _props$row140$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row141 = props.row) !== null && _props$row141 !== void 0 && _props$row141.permissions ? [_vm._l((_props$row142 = props.row) === null || _props$row142 === void 0 ? void 0 : (_props$row142$permiss = _props$row142.permissions) === null || _props$row142$permiss === void 0 ? void 0 : _props$row142$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": "Subscriptions",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.subscriptions,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row143, _props$row143$permiss, _props$row145, _props$row146, _props$row146$permiss, _props$row147, _props$row148, _props$row148$permiss, _props$row149, _props$row150, _props$row150$permiss, _props$row151, _props$row152, _props$row152$permiss, _props$row153, _props$row154, _props$row154$permiss, _props$row155, _props$row156, _props$row156$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row143 = props.row) === null || _props$row143 === void 0 ? void 0 : (_props$row143$permiss = _props$row143.permissions) === null || _props$row143$permiss === void 0 ? void 0 : _props$row143$permiss.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row144, _props$row144$permiss;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row144 = props.row) === null || _props$row144 === void 0 ? void 0 : (_props$row144$permiss = _props$row144.permissions) === null || _props$row144$permiss === void 0 ? void 0 : _props$row144$permiss.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row145 = props.row) !== null && _props$row145 !== void 0 && _props$row145.permissions ? [_vm._l((_props$row146 = props.row) === null || _props$row146 === void 0 ? void 0 : (_props$row146$permiss = _props$row146.permissions) === null || _props$row146$permiss === void 0 ? void 0 : _props$row146$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row147 = props.row) !== null && _props$row147 !== void 0 && _props$row147.permissions ? [_vm._l((_props$row148 = props.row) === null || _props$row148 === void 0 ? void 0 : (_props$row148$permiss = _props$row148.permissions) === null || _props$row148$permiss === void 0 ? void 0 : _props$row148$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row149 = props.row) !== null && _props$row149 !== void 0 && _props$row149.permissions ? [_vm._l((_props$row150 = props.row) === null || _props$row150 === void 0 ? void 0 : (_props$row150$permiss = _props$row150.permissions) === null || _props$row150$permiss === void 0 ? void 0 : _props$row150$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row151 = props.row) !== null && _props$row151 !== void 0 && _props$row151.permissions ? [_vm._l((_props$row152 = props.row) === null || _props$row152 === void 0 ? void 0 : (_props$row152$permiss = _props$row152.permissions) === null || _props$row152$permiss === void 0 ? void 0 : _props$row152$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row153 = props.row) !== null && _props$row153 !== void 0 && _props$row153.permissions ? [_vm._l((_props$row154 = props.row) === null || _props$row154 === void 0 ? void 0 : (_props$row154$permiss = _props$row154.permissions) === null || _props$row154$permiss === void 0 ? void 0 : _props$row154$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row155 = props.row) !== null && _props$row155 !== void 0 && _props$row155.permissions ? [_vm._l((_props$row156 = props.row) === null || _props$row156 === void 0 ? void 0 : (_props$row156$permiss = _props$row156.permissions) === null || _props$row156$permiss === void 0 ? void 0 : _props$row156$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), ((_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : _vm$authUser.company_id) == _vm.companyId ? _c('b-card-actions', {
    attrs: {
      "title": "Outreach",
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "max-height": "600px",
      "styleClass": "vgt-table striped",
      "fixed-header": true,
      "columns": _vm.columns,
      "rows": _vm.outreach,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row157, _props$row157$permiss, _props$row159, _props$row160, _props$row160$permiss, _props$row161, _props$row162, _props$row162$permiss, _props$row163, _props$row164, _props$row164$permiss, _props$row165, _props$row166, _props$row166$permiss, _props$row167, _props$row168, _props$row168$permiss, _props$row169, _props$row170, _props$row170$permiss;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'all' ? _c('div', [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.roleHasPermissionsIncludes(_vm.mapPermissionsIds((_props$row157 = props.row) === null || _props$row157 === void 0 ? void 0 : (_props$row157$permiss = _props$row157.permissions) === null || _props$row157$permiss === void 0 ? void 0 : _props$row157$permiss.data)),
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change(isChecked) {
              var _props$row158, _props$row158$permiss;
              return _vm.onChangeSwitch(isChecked, _vm.mapPermissionsIds((_props$row158 = props.row) === null || _props$row158 === void 0 ? void 0 : (_props$row158$permiss = _props$row158.permissions) === null || _props$row158$permiss === void 0 ? void 0 : _props$row158$permiss.data), true);
            }
          }
        })], 1) : props.column.field === 'access' ? _c('div', [(_props$row159 = props.row) !== null && _props$row159 !== void 0 && _props$row159.permissions ? [_vm._l((_props$row160 = props.row) === null || _props$row160 === void 0 ? void 0 : (_props$row160$permiss = _props$row160.permissions) === null || _props$row160$permiss === void 0 ? void 0 : _props$row160$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row161 = props.row) !== null && _props$row161 !== void 0 && _props$row161.permissions ? [_vm._l((_props$row162 = props.row) === null || _props$row162 === void 0 ? void 0 : (_props$row162$permiss = _props$row162.permissions) === null || _props$row162$permiss === void 0 ? void 0 : _props$row162$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row163 = props.row) !== null && _props$row163 !== void 0 && _props$row163.permissions ? [_vm._l((_props$row164 = props.row) === null || _props$row164 === void 0 ? void 0 : (_props$row164$permiss = _props$row164.permissions) === null || _props$row164$permiss === void 0 ? void 0 : _props$row164$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row165 = props.row) !== null && _props$row165 !== void 0 && _props$row165.permissions ? [_vm._l((_props$row166 = props.row) === null || _props$row166 === void 0 ? void 0 : (_props$row166$permiss = _props$row166.permissions) === null || _props$row166$permiss === void 0 ? void 0 : _props$row166$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row167 = props.row) !== null && _props$row167 !== void 0 && _props$row167.permissions ? [_vm._l((_props$row168 = props.row) === null || _props$row168 === void 0 ? void 0 : (_props$row168$permiss = _props$row168.permissions) === null || _props$row168$permiss === void 0 ? void 0 : _props$row168$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row169 = props.row) !== null && _props$row169 !== void 0 && _props$row169.permissions ? [_vm._l((_props$row170 = props.row) === null || _props$row170 === void 0 ? void 0 : (_props$row170$permiss = _props$row170.permissions) === null || _props$row170$permiss === void 0 ? void 0 : _props$row170$permiss.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change(isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }], null, false, 2168554409)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }